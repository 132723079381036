import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../../core/hooks/useLocalStorage";
import { UserRoles } from "../../utils/UserRoles";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";

interface AuthContextInterface {
  hasRole: (roles?: number[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  userInfo?: UserInfo;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authKey, setAuthKey] = useLocalStorage<string>("authkey", "");
  const [token, setToken] = useLocalStorage<string>("token", "");

  const { isLoggingIn, login } = useLogin();
  const { isLoggingOut, logout } = useLogout();
  const { data: userInfo } = useUserInfo(authKey);

  const hasRole = (roles?: number[]) => {
    if (!roles)
      return true;

    if (!userInfo)
      return false;

    return [UserRoles.ADMINS, UserRoles.MANAGERS].concat(roles).includes(userInfo.user.role_id);
  };

  const handleLogin = async (email: string, password: string) => {
    return login({ email, password })
      .then((key: object) => {
        const usrInfo = key as UserInfo;
        if (usrInfo) {
          setAuthKey(JSON.stringify(key));
          setToken(usrInfo?.access_token)
        }
        return key;
      })
      .catch((err: any) => {
        throw err;
      });
  };

  const handleLogout = async () => {
    // return logout()
    //   .then((data) => {
    //     setAuthKey("");
    //     return data;
    //   })
    //   .catch((err) => {
    //     throw err;
    //   });
    setAuthKey("");
    setToken("");
  };

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
