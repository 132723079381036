import { compose } from '@material-ui/system'
import axios from 'axios'
// export const API_URL = "https://sandbox.izivan.com.vn/api/"
// export const API_URL = "http://localhost:4000/api/"
export const API_URL = "https://admin.showaura.com/api/"

const request = axios.create({
   baseURL: API_URL,
})

export default request