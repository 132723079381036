import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";
import { UserRoles } from "./utils/UserRoles";

// Admin
const Profile = lazy(() => import("./admin/pages/Profile"))
const Admin = lazy(() => import("./admin/pages/Admin"));
const Home = lazy(() => import("./admin/pages/Home"));
const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const Login = lazy(() => import("./auth/pages/Login"));
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UserManagement = lazy(() => import("./users/pages/UserManagement"));
const ExcelFileManagement = lazy(() => import("./excel/pages/ExcelFileManagement"));
const UpdateOrder = lazy(() => import("./admin/pages/UpdateOrder"));

const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));

const AppRoutes = () => {
  return (
    <Routes basename={process.env.PUBLIC_URL}>
      <PrivateRoute path="" element={<Admin />}>
        <PrivateRoute roles={[UserRoles.ADMINS, UserRoles.MANAGERS]} path="/" element={<Dashboard />} />
        <PrivateRoute path="/orders" element={<Home />} />
        <PrivateRoute roles={[]} path="/user-management" element={<UserManagement />} />

        <PrivateRoute roles={[]} path="/excels" element={<ExcelFileManagement />} />
        <PrivateRoute path="/profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
        </PrivateRoute>
      </PrivateRoute>
      <Route
        path="*"
        element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace />}
      />
      <Route path="login" element={<Login />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
