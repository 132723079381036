import React from 'react';
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./auth/contexts/AuthProvider";
import AppRoutes from "./AppRoutes";
import Loader from "./core/components/Loader";
import SettingsProvider from './core/contexts/SettingsProvider';
import QueryWrapper from './core/components/QueryWrapper';
import SnackbarProvider from './core/contexts/SnackbarProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      suspense: true,
    },
  },
});

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Sentry.ErrorBoundary fallback={"An error has occurred"}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <QueryWrapper>
              <SnackbarProvider>
                <AuthProvider>
                  <AppRoutes />
                </AuthProvider>
              </SnackbarProvider>
            </QueryWrapper>
          </SettingsProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </React.Suspense>
  );
}

export default App;
